@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  letter-spacing: 0.4px;
}
.language div#google_translate_element .skiptranslate.goog-te-gadget span {
  display: none;
}
/* Hide the Google Translate banner */
/* Hide the Google Translate banner */

/* Hide the Google Translate branding text */
/*  */

.goog-te-gadget{
  color: rgb(39 41 47) !important ;
}
.goog-te-gadget select{
  height: 35px;
  width: 140px;
  border-radius: 6px
}
.goog-te-gadget .goog-te-combo{
  margin: 7px  !important ;
}